(function() { 'use strict';
  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$HEADER_MENU = $('.header-menu-container > ul');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');

  // Helpers
  // -------
  window.IS_MOBILE = !!navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)|(Windows Phone)/i);
  window.IS_DESKTOP = !IS_MOBILE;
  window.IS_TOUCH_DEVICE = 'ontouchend' in document || !!navigator.msMaxTouchPoints || !!navigator.maxTouchPoints;

  $WINDOW
    .on('resize', function() {
      window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
      window.WINDOW_HEIGHT = $WINDOW.height();
      window.HEADER_HEIGHT = $HEADER.outerHeight();
    })
    .trigger('resize');

  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDTH = function() {
    return WINDOW_WIDTH >= DESKTOP_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH >= PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH < DESKTOP_WIDTH );
  };
  /**
   * @return boolean
   */
  window.IS_WIDTH_LESS_PORTRAIT_TABLET = function() {
    return WINDOW_WIDTH < PORTRAIT_TABLET_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_TABLET_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_TABLET_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_SMALL_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < SMALL_MOBILE_WIDTH;
  };


  // Variables
  var slickSettings = {
        autoplay: true,
        autoplaySpeed: 8000,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        pauseOnDotsHover: true,
        customPaging: function() { return $('<div />'); },
        draggable: false
      };


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  // Phone
  $('input[type="tel"]').inputmask({
    regex: '\\+7 \\([0-6,9]\\d\\d\\) \\d{3}(\\-\\d{2}){2}',
    placeholder: '+7 (___) ___-__-__'
  });


  $WINDOW.on('load', function() {
    if( IS_DESKTOP_WIDTH() )
      $DOCUMENT.trigger('fixHeaderSubmenuPosition');
  });


  $BODY
    .on('yiiListViewUpdating', function() {
      $.mouseLoader(true);
    })
    .on('yiiListViewUpdated', function() {
      $DOCUMENT.trigger('initSelectric');
      $.mouseLoader(false);
    });


  $DOCUMENT
    .ready(function() {
      $HEADER.addClass('fixed');

      $DOCUMENT
        .trigger('setBodyPaddingTop')
        .trigger('headerMenuEvents')
        .trigger('initSelectric');

      $WINDOW.on('resize', function() {
        $DOCUMENT
          .trigger('setBodyPaddingTop')
          .trigger('headerMenuEvents');

        if( IS_DESKTOP_WIDTH() )
          $DOCUMENT.trigger('fixHeaderSubmenuPosition');
      });

      // Header menu button
      // ------------------
      $MENU_CHECKBOX
        .on('change', function() {
          if( this.checked ) {
            $BODY.addClass('menu-is-opened');
          } else {
            $BODY.removeClass('menu-is-opened');
          }
        })
        .prop('checked', false)
        .prop('disabled', false)
        .trigger('change');


      // Main page banner rotator
      // ------------------------
      $('.main-rotator').slick($.extend({}, slickSettings, {
        fade: true,
        arrows: false,
        dots: true,
        centerMode: true,
        centerPadding: '0px',
        slide: '.main-rotator-slide',
        appendDots: '.main-rotator-dots',
        draggable: true
      }));


      // Product gallery
      // ---------------
      $('.product-gallery-container .product-gallery').slick($.extend({}, slickSettings, {
        fade: true,
        dots: true,
        slide: '.product-gallery-image',
        appendArrows: '.product-gallery-container .product-gallery-arrows',
        appendDots: '.product-gallery-container .product-gallery-pager',
        customPaging: function(slider, index) { return $('<div style="background-image:url(\''.concat(slider.$slides.eq(index).data('pre'), '\')"/>')); },
        draggable: true
      }));


      // Portfolio rotator
      // -----------------
      $('.portfolio-rotator-container').each(function() {
        $(this).find('.portfolio-rotator > div > div').slick($.extend({}, slickSettings, {
          appendArrows: $(this).children('.portfolio-rotator-arrows'),
          slide: '.list-view-item',
          slidesPerRow: 4,
          slidesToShow: 4,
          draggable: true,
          swipeToSlide: true,
          responsive: [
            {
              breakpoint: DESKTOP_WIDTH,
              settings: {slidesToShow: 3}
            },
            {
              breakpoint: PORTRAIT_TABLET_WIDTH,
              settings: {slidesToShow: 2}
            },
            {
              breakpoint: 400,
              settings: {slidesToShow: 1}
            }
          ]
        }));
      });
    })

    // Set body padding top
    // --------------------
    .on('setBodyPaddingTop', function() {
      $BODY.css('padding-top', HEADER_HEIGHT);
    })

    // Fix header submenu popup position
    // ---------------------------------
    .on('fixHeaderSubmenuPosition', function() {
      var headerMenuWidth = $HEADER_MENU.outerWidth();

      $HEADER_MENU.find('> li > ul').each(function() {
        var $submenu = $(this).css('max-width', headerMenuWidth),
            $parent = $submenu.parent(),
            position = headerMenuWidth - $submenu.outerWidth() - $parent.position().left;

        if( position < 0 )
          $submenu.css('left', position);
      });
    })

    // Header menu events
    // ------------------
    .on('headerMenuEvents', function() {
      if( IS_DESKTOP_WIDTH() ) {
        if( !$HEADER_MENU.hasClass('is-desktop-events') ) {
          $DOCUMENT
            .off('click.closeHeaderMenu')
            .off('click.headerMenu');

          $MENU_CHECKBOX.prop('checked', false).trigger('change');

          $HEADER_MENU
            .css('top', 'auto')
            .addClass('is-desktop-events')
            .removeClass('is-mobile-events')

            .find('li').removeClass('is-hovered')
            .find('ul').removeAttr('style');
        }
      }
      else {
        $HEADER_MENU.css('top', HEADER_HEIGHT);

        if( !$HEADER_MENU.hasClass('is-mobile-events') ) {
          $HEADER_MENU
            .addClass('is-mobile-events')
            .removeClass('is-desktop-events')

            .find('> li > ul').removeAttr('style');

          $DOCUMENT
            // Close header menu
            // -----------------
            .on('click.closeHeaderMenu', function(e) {
              if( !($(e.target).closest('.header-menu-checkbox').length || $(e.target).closest('.header-menu-button').length || $(e.target).closest($HEADER_MENU).length) ) {
                $MENU_CHECKBOX.prop('checked', false).trigger('change');

                e.stopPropagation();
              }
            })

            // Header menu items
            .on('click.headerMenu', '.header-menu-container > ul a, .header-menu-container > ul span, .header-menu-container > ul .dropmarker', function(e) {
              e.preventDefault();

              var $self = $(this),
                  $parent = $self.closest('li'),
                  isWithDropdown = $parent.hasClass('with-dropdown'),
                  isOnHover = $parent.hasClass('is-hovered'),
                  $elementsToClose = $parent.siblings('.with-dropdown:not(.icn-menu-service-group-null)').add($parent.siblings('.icn-menu-service-group-null').find('> ul > li.with-dropdown'));

              if( $parent.closest('ul').closest('.icn-menu-service-group-null').length )
                $elementsToClose = $elementsToClose.add($parent.closest('ul').closest('.icn-menu-service-group-null').siblings('.with-dropdown'));

              $elementsToClose.removeClass('is-hovered').children('ul').slideUp(300);

              if( isWithDropdown ) {
                if( isOnHover ) {
                  if( $self.prop("tagName").toUpperCase() === 'A' )
                    location.href = $self.prop('href');
                  else
                    $parent.removeClass('is-hovered').children('ul').slideUp(300);
                } else {
                  $parent.addClass('is-hovered').children('ul').slideDown(300);
                }
              } else {
                if( $self.prop("tagName").toUpperCase() === 'A' )
                  location.href = $self.prop('href');
              }
            });
        }
      }
    })

    // Init selectric
    // --------------
    .on('initSelectric', function() {
      $('select').selectric({
        disableOnMobile: false,
        nativeOnMobile: true,
        openOnHover: true,
        hoverIntentTimeout: 0,
        inheritOriginalWidth: false
      });
    })

    .on('click.headerCallbackPhoneLink', '.header .header-contacts .callback-phone-link', function() {
      CALLBACKS.headerCallbackPhoneLink();
    })
    .on('click.footerCallbackPhoneLink', '.footer .footer-phones .callback-phone-link', function() {
      CALLBACKS.footerCallbackPhoneLink();
    })
    .on('click.fastOrderShowLink', '.product-card .fast-order-show-basket', function() {
      CALLBACKS.fastOrderShowLink();
    })

    // Product price tabs
    // ------------------
    .on('click.productPriceTabs', '.product-card-right .product-prices > div > div', function(e) {
      e.preventDefault();

      $(this).parent().addClass('active').siblings().removeClass('active').closest('.product-prices').addClass('initialized');
    })

    // Price groupped quick links
    // --------------------------
    .on('click.priceGrouppedQuickLinks', '.price-groupped-table-container .quick-link-list a', function(e) {
      e.preventDefault();

      var $link = $(this);

      if( !$link.parent().hasClass('active') ) {
        $link
          .parent().addClass('active')
          .siblings().removeClass('active')
          .first().parent().parent().addClass('selected')
          .parent().find('.price-table-container .price-table').addClass('selected')
          .find($link.attr('href').substr($link.attr('href').lastIndexOf('#')))
          .parent().addClass('active')
          .siblings().removeClass('active');
      }
    })

    // Scroll to
    // ---------
    .on('click.jsScrollTo', '.js-scroll-to', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          $elemToScroll = $($lnk.attr('href').substr($lnk.attr('href').lastIndexOf('#'))),
          speed = $lnk.data('scrollSpeed') || 150,
          offset = ($lnk.data('scrollOffset') || 0) - HEADER_HEIGHT;

      $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
    })

    // Show-hide block
    // ---------------
    .on('click.jsShowHide', '.js-show-hide > div:first-child > span', function(e) {
      e.preventDefault();

      var $self = $(this),
          $container = $self.closest('.js-show-hide');

      if( $container.hasClass('active') )
        $self.text($container.removeClass('active').data('text'));
      else
        $self.text($container.addClass('active').data('textActive'));
    });


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    autoFocus: false,
    loop: true,
    infobar: false,
    smallBtn: true,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    transitionEffect: 'slide',
    hash: false,
    mobile: {
      clickSlide: 'close'
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    },

    beforeLoad: function(widget, current) {
      switch(current.type) {
        case 'image':
          current.opts.protect = true;
          break;

        case 'inline':
          current.opts.touch = false;
          break;
      }
    },
    afterLoad: function(widget, current) {
      if( current.contentType === 'html' && !IS_MOBILE && !IS_MOBILE_WIDTH() ) {
        current.$content.find('.js-autofocus-inp').trigger("focus");
      }
    }
  });
})();
